import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgMinus(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path className="fillSecondary" d="M1 7h14v2H1z" fillRule="evenodd" />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgMinus;
