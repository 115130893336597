import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgMessage(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M1.134 3.112c.44-.065.883-.093 1.327-.082 4.113.03 8.229-.03 12.341.03a2.863 2.863 0 01-1.263 2.733C12.127 6.87 10.684 7.912 9.304 9.03c-.38.356-.869.577-1.39.632a3.385 3.385 0 01-1.37-.756C5.143 7.761 3.667 6.702 2.249 5.577a3.057 3.057 0 01-1.114-2.465zm.025 3.027c1.55 1.256 3.195 2.403 4.74 3.665a3.24 3.24 0 002.467.94c1-.356 1.909-.923 2.663-1.66 1.251-1.009 2.587-1.916 3.821-2.945a1019.1 1019.1 0 000 6.101c-.027.225.046.493-.11.688a4.159 4.159 0 01-.58.046H1.996a7.426 7.426 0 01-.832-.075l-.005-6.76zM.982 2.08A1.447 1.447 0 00.01 3.617c.019 2.975.055 5.952 0 8.925-.006.395.154.776.442 1.05.288.275.68.42 1.08.4l12.93.005c.485.03.954-.18 1.249-.561.239-.403.334-.873.27-1.335-.045-2.923.018-5.853-.024-8.78a1.348 1.348 0 00-.462-.97 1.39 1.39 0 00-1.033-.337H2.448a6.753 6.753 0 00-1.466.066z"
          className="fillSecondary"
          fillRule="nonzero"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgMessage;
