import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgLocation(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <path
            d="M4.053 7.603l3.632 1.21a.785.785 0 01.497.497l1.21 3.633 4.273-9.612-9.612 4.272zm2.763 2.577L1.635 8.453c-.68-.227-.726-1.172-.07-1.463l13.328-5.924c.657-.292 1.329.38 1.037 1.037L10.006 15.43c-.291.656-1.236.61-1.463-.07L6.816 10.18z"
            className="fillSecondary"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgLocation;
