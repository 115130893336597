import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgArrowRight(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M9.414 14.414L8 13l4-4H0V7h12L8 3l1.414-1.414L15.828 8l-6.414 6.414z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgArrowRight;
