import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowDownTiny(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M12.293 4.586L13.707 6 8 11.707 2.293 6l1.414-1.414L8 8.879z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowDownTiny;
