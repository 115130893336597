import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowUp(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M13.293 11.707l1.414-1.414L8 3.586l-6.707 6.707 1.414 1.414L8 6.414z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowUp;
