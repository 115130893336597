import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSearch(props: IconProps) {
  return (
    <IconStyles.Container {...props} className="IconWrapper">
      <svg width={16} height={16} viewBox="0 0 16 16">
        <defs>
          <path
            d="M16 14.767l-3.906-3.905a6.029 6.029 0 001.233-3.7A6.126 6.126 0 007.164 1 6.127 6.127 0 001 7.164a6.127 6.127 0 006.164 6.164 6.029 6.029 0 003.7-1.233L14.767 16 16 14.767zM4.038 4.041A4.36 4.36 0 017.16 2.764a4.36 4.36 0 014.4 4.4 4.36 4.36 0 01-4.4 4.4 4.36 4.36 0 01-4.4-4.4 4.36 4.36 0 011.277-3.123z"
            id="Search_svg__a"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <use className="fillSecondary" xlinkHref="#Search_svg__a" />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSearch;
