import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgPhone(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M3.176 1.088c.32-.085.655-.109.985-.07.46.651.867 1.338 1.219 2.053.241.463.665.874.7 1.416-.43.82-1.49 1.137-1.813 2.016a9.805 9.805 0 005.325 5.181c.811-.34 1.137-1.304 1.874-1.767.457-.073.81.311 1.186.506.805.422 1.583.894 2.329 1.414a2.577 2.577 0 01-.869 2.26 3.951 3.951 0 01-3.347.814c-1.479-.36-2.88-.99-4.132-1.856a17.246 17.246 0 01-3.677-3.653 13.319 13.319 0 01-1.848-4.09 4.002 4.002 0 01.952-3.636c.3-.313.69-.52 1.117-.593l-.001.005z"
          className="fillSecondary"
          fillRule="nonzero"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgPhone;
