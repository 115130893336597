import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowDown(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M13.293 4.586L14.707 6 8 12.707 1.293 6l1.414-1.414L8 9.879z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowDown;
