import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgTime(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <path
            d="M8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm1 2v3h2v2H7V4h2z"
            className="fillSecondary"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgTime;
