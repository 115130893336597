import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgInfoEmpty(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h16v16H0z" />
          <path
            d="M8 .5a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM8 2a6 6 0 100 12A6 6 0 008 2zm-.548 4.517c.079 0 .154.013.223.037a.695.695 0 01.92.658v2.928l.525.001a.68.68 0 010 1.359H6.217a.68.68 0 010-1.359l.988-.001V7.875h-.247a.68.68 0 010-1.358h.494zM8 3.6a1 1 0 110 2 1 1 0 010-2z"
            className="fillSecondary"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgInfoEmpty;
