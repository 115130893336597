import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowLeft(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M10.414 1.586L11.828 3l-5 5 5 5-1.414 1.414L4 8l6.414-6.414z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowLeft;
