import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowRight(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M6.414 14.414L5 13l5-5-5-5 1.414-1.414L12.828 8l-6.414 6.414z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowRight;
