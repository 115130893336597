import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgSemiArrowRightTiny(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M6.414 13.414L5 12l4-4-4-4 1.414-1.414L11.828 8l-5.414 5.414z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgSemiArrowRightTiny;
