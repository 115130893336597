import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgCheck(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={16} height={16} viewBox="0 0 16 16">
        <path
          d="M5.852 11.601L1.625 8.22.375 9.781l5.773 4.618 9.626-11.766-1.548-1.266z"
          className="fillSecondary"
          fillRule="evenodd"
        />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgCheck;
